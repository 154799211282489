/* 타이틀 부분 */
.marketing_titleBox {
  border-bottom: 1px solid #babfc9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}
.marketing_titleBox > div {
  display: flex;
}
.marketing_titleBox > div > .title {
  font-size: 28px;
  font-family: Medium;
}

.marketing_titleBox > div > .pluseButton {
  width: 30px;
  height: 25px;
  background-color: #15254e;
  border-radius: 100px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  margin-left: 10px;
  color: #fff;
  cursor: pointer;
}
.marketing_titleBox > .pluseButton:hover {
  opacity: 0.6;
}
.marketing_titleBox > .forceQuitButton {
  border: 1px solid #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  background-color: #ea4848;
  box-shadow: 1px 1px 2px 0px rgb(21 37 78 / 15%);
  font-family: "Medium";
  cursor: pointer;
}

p.errorText {
  padding-top: 5px;
  font-size: 14px;
  color: #c82323;
}

/* 표 리스트 아이템 */
.marketing_tableListItem {
  display: grid;
  grid-template-columns: 100px auto 150px 150px 150px;
}
.marketing_tableListItem:not(.tableTitle) {
  cursor: pointer;
}
.marketing_tableListItem:not(.tableTitle):hover {
  background-color: #f4f4f450;
}
.marketing_tableListItem:not(.tableTitle):active {
  background-color: #f4f4f4;
}

.marketing_tableListItem.tableTitle {
  background-color: #f7f9ff;
  border-bottom: 1px solid #e4e4e5;
}
.marketing_tableListItem.tableTitle > p {
  padding: 10px 20px;
}
.marketing_tableListItem:not(.tableTitle) > p {
  padding: 25px 20px;
  border-bottom: 1px solid #babfc9;
}
.marketing_tableListItem > p:not(:nth-child(2)) {
  text-align: center;
}
.marketing_tableListItem > p.updatedAdminNone {
  font-size: 14px;
  color: #a3a3ab;
  font-family: "Regular";
}

/* 인풋 스타일 라디오 제외 */
.marketing_input {
  border: none;
  padding: 6px 10px;
  border-radius: 3px;
  background-color: #f4f4f4;
  flex-grow: 1;
  height: 40px;
  font-size: 14px;
  outline: none;
}
.marketing_input::placeholder {
  font-size: 14px;
}
